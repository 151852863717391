<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import countTo from "vue-count-to";
import Notification from "@/services/Notification";
import _ from "underscore";


/**
 * Starter page
 */
export default {
    components: { Layout, PageHeader, countTo },
    page: {
        title: "Notification Details",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    async created() {
        this.getNotificationByGroup(this.$route.query.id)
    },
    computed: {
       computedWidth() {
            return this.stats.processed_percent + '%';
       },
       computedProcessingRange() {
           return this.notifInfo.processing_range;
       },
       computedCompleted() {
           return this.notifInfo.completed;
       },
    },
    watch: {
        filter: {
            handler: _.debounce(function () {
                this.currentPage = 1;
                this.getNotificationRecipients(this.$route.query.id)
            }, 1500),
            deep: true
        },
    },
    methods: {

        getFilters(){
            var filter_string = '';

            return filter_string = filter_string.substring(1);
        },
        loadFullTable() {
            this.showLoader_c1 = true;
            this.getNotificationRecipients(this.$route.query.id)
                .then(() => {
                    this.showLoader_c1 = false;
                    this.showFullTable = true;
                })
                .catch((error) => {
                    console.error('Error loading full table:', error);
                    this.showLoader_c1 = false;
                });
        },


        getNotificationByGroup(id){
            Notification.getNotificationByGroup(id)
            .then(response => {
                this.notifInfo = response.data.notification;
                this.stats = response.data.stats;
                this.totalItems = this.stats.processed.IOS + this.stats.processed.Android;
//                this.getNotificationRecipients(this.$route.query.id);
            })
            .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "";
            })
        },

        getNotificationRecipients(id) {
            this.showLoader_c1 = true;
          var filters1 = this.getFilters()
          let filters = `${id}?totalItems=${this.totalItems}`+filters1;
          let paginationFilters = this.setPaginationFilters(filters)
          return Notification.getNotificationRecipients(paginationFilters)
              .then(response => {
                this.notifRecipient = response.data.result;
                this.totalItems = this.stats.processed.IOS + this.stats.processed.Android;
                this.totalPages = Math.ceil(this.totalItems / this.perPage);
              })
                .catch(error => {
                    console.error('Error loading notification recipients:', error);
                    throw error;
                })
                .finally(() => {
                    this.showLoader_c1 = false;
                });
        },

      formatToken(token) {
        if (!token) return '';
        const start = token.substring(0, 40);
        return `${start}...`;
      },

      copyToken(token) {
        navigator.clipboard.writeText(token)
            .then(() => {
              this.showToast('Token copied to clipboard');
            })
            .catch(err => {
              console.error('Error copying token:', err);
              this.showToast('Failed to copy token');
            });
      },
      showToast(message) {
        this.toastMessage = message; // Set the message
        setTimeout(() => {
          this.toastMessage = null; // Clear the message after 3 seconds
        }, 3000);
      },

        handlePaginationChange(){
            if(this.$route.query.id){
            this.getNotificationRecipients(this.$route.query.id)
            }
        },
      async exportToExcel() {
        try {
          this.exportingData = true; // Show loading or disable export button
          const response = await Notification.exportNotificationRecipients(this.$route.query.id);
          const responseData = response.data.result;
          console.log("API Response:", response);

          if (responseData && responseData.length > 0) {
            const ExcelJS = require("exceljs");
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Notification Recipients");

            // Define column headers
            const columnHeaders = [
              { key: "user", header: "User", width: 15 },
              { key: "email", header: "Email", width: 25 },
              { key: "token", header: "Token", width: 50 },
              { key: "device_platform", header: "Device Platform", width: 20 },
              { key: "success", header: "Success", width: 10 },
              { key: "error", header: "Error", width: 10 },
            ];

            // Add column definitions to the worksheet
            worksheet.columns = columnHeaders;

            // Add data rows to the worksheet
            responseData.forEach(item => {
              worksheet.addRow({
                user: item.user || "N/A",
                email: item.email || "N/A",
                token: item.token || "N/A",
                device_platform: item.device_platform || "N/A",
                success: item.success || "N/A",
                error: item.error || "N/A",
              });
            });

            // Make the first row (headers) bold
            worksheet.getRow(1).font = { bold: true };

            // Generate the Excel file and trigger the download
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "NotificationRecipients.xlsx";
            link.click();
          } else {
            alert("No data available to export.");
          }
        } catch (error) {
          console.error("An error occurred:", error);
          alert("An error occurred while exporting data. Please try again.");
        } finally {
          this.exportingData = false; // Hide loading or re-enable export button
        }
      },

    },
    data() {
        return {
            title: "Notification Details",
            items: [
                {
                    text: "Notifications",
                    href: "/notifications",
                },
                {
                    text: this.$route.query.id,
                    active: true,
                },
            ],
            notifInfo: {

            },

            stats: {
                processed: {
                    IOS: 0,
                    Android: 0
                },
                success: {
                    IOS: 0,
                    Android: 0
                },
                error: {
                    IOS: 0,
                    Android: 0
                },
                processed_percent:0
            },
            filter: "",
            totalPages: 0,
            totalItems: 0,
            currentPage: 1,
            perPage: 10,
            showLoader_c1:false,
            showLoader: false,
            showFullTable: false,
            toastMessage: null,
            itemsPerPageOptions: [10, 25, 50, 100],
            tableHeaders:[
                { text: 'User', value: 'user' },
                { text: 'Email', value: 'email' },
                { text: 'Token', value: 'token' },
                { text: 'Device Platform', value: 'device_platform' },
                { text: 'Success', value: 'success' },
                { text: 'Error', value: 'error' },
            ],
            notifRecipient: [],
        };
    },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
        <div v-if="!computedCompleted" class="alert alert-info mb-0" role="alert">
              Push notification processing in progress ({{computedProcessingRange}} minutes)
             <div class="progress" style="height: 30px">
                 <div class="progress-bar " role="progressbar" aria-valuenow="stats.processed_percent" aria-valuemin="0" aria-valuemax="100" :style="{ width: computedWidth }">{{computedWidth}}</div>
              </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-primary"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-primary">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.processed.IOS + stats.processed.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-primary" style="font-size: x-large;">Notification Processed</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.processed.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.processed.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-success"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-success">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.success.IOS + stats.success.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-success" style="font-size: x-large;">Notification Accepted</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.success.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.success.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i class="mdi mdi-48px mdi-send me-2 text-danger"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-danger">
                                <span data-plugin="counterup ">
                                    <countTo :startVal="0" :endVal="stats.error.IOS + stats.error.Android" :duration="1000"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-danger" style="font-size: x-large;">Notification Rejected</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-secondary me-5">
                                <i class="mdi mdi-24px mdi-apple me-2"></i>IOS 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.error.IOS" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-secondary">
                                <i class="mdi mdi-24px mdi-android me-2"></i>ANDROID  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="stats.error.Android" :duration="2000"></countTo>
                                    </span>
                                </b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
<!-- end row-->

    <div class="row">
        <div class="col-md-6 col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title center">Notification Data</h4>
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Message</th>
                                    <th>Recipient</th>
                                    <th>Type</th>
                                    <th>Language</th>
                                    <th>Processing Range</th>
                                    <th>Set Up / Scheduled	</th>
                                    <th>Initiated</th>
                                    <th>Completed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{{notifInfo.notification_id}}</th>
                                    <td>{{notifInfo.title}}</td>
                                    <td>{{notifInfo.message}}</td>
                                    <td>{{notifInfo.recipient}}</td>
                                    <td>{{notifInfo.type}}</td>
                                    <td>{{notifInfo.language}}</td>
                                    <td>{{notifInfo.processing_range}}</td>
                                    <td>{{notifInfo.set_up}}</td>
                                    <td>{{notifInfo.initiated}}</td>
                                    <td>{{notifInfo.completed}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div class="row" v-if="computedCompleted">
          <div class="col-md-6 col-xl-12">
              <div class="card">
                  <div class="card-body row">
                      <div class="card-title center col-2">Notification Recipients</div>
                      <div class="col-10 " v-if="!showFullTable " >
                          <button @click="loadFullTable" class="btn btn-primary ">
                              See More
                          </button>
                      </div>

                      <div class="table-responsive">
                          <v-app>
                              <v-data-table
                                      v-if="showFullTable"
                                      :headers="tableHeaders"
                                      :items="notifRecipient"
                                      :search="filter"
                                      :items-per-page="perPage"
                                      :page="currentPage"
                                      :server-items-length="totalItems"
                                      :loading="showLoader"
                                      item-key="token"
                                      @update:page="onPageChange"
                                      @update:items-per-page="onItemsPerPageChange"
                              >

                                  <template v-slot:item.email="{ item }">
                                      <span v-if="!item.id">User not registered</span>
                                      <router-link v-else title="View Customer" :to="{ path: `/customers/profile/${item.id}`}">
                                          {{item.email}}
                                      </router-link>
                                  </template>


                                  <template v-slot:item.token="{ item }">
                                    <v-row>
                                      <v-col sm="10">
                                        <span>{{ formatToken(item.token) }}</span>
                                      </v-col>
                                      <v-col cols="2">
                                        <v-btn icon @click="copyToken(item.token)" title="Copy Token">
                                          <v-icon class="text-primary fs-6">mdi-content-copy</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                    <div v-if="toastMessage" class="toast-message">
                                      {{ toastMessage }}
                                    </div>
                                  </template>

                                  <template v-slot:footer>
                                      <v-row v-if="showLoader_c1" class="mx-4">
                                          <v-col class="text-center">
                                              <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col >
                                              <v-pagination v-model="currentPage" :length="totalPages" :total-visible="6" @input="onPageChange" circle></v-pagination>
                                          </v-col>
                                      </v-row>
                                  </template>

                                  <template v-slot:top="{ pagination, options, updateOptions }">
                                      <v-row>
                                          <v-col md="6" sm="12">
                                              <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" :items-per-page-options="itemsPerPageOpts"/>
                                          </v-col>
                                         <v-col md="3" sm="12" class="text-end">
                                           <b-button
                                               variant="success"
                                               title="Export Data"
                                               @click="exportToExcel()"
                                               :disabled="exportingData"
                                           >Export Data <b-spinner v-show="exportingData" small></b-spinner
                                           ></b-button>
                                          </v-col>
                                          <v-col md="3" sm="12">
                                              <v-text-field v-model="filter" :append-icon="filter ? 'mdi-close' : 'mdi-magnify'" @click:append="clearSearch" label="Search" single-line hide-details outlined dense></v-text-field>
                                          </v-col>
                                      </v-row>
                                  </template>
                              </v-data-table>
                          </v-app>

                      </div>
                  </div>
              </div>
          </div>
      </div>


  </Layout>
</template>
<style>
.toast-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 14px;
  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}
</style>
